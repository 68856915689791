<template>
  <div class="main">
    <!-- header end-->
    <div class="textbox" style="padding: 30px 0 0 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/interaction' }"
          >企业互动</el-breadcrumb-item
        >
        <el-breadcrumb-item>互动详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox" v-if="Obj.State">
      <div class="textbox">
        <div class="leftbox">
          <div class="synctitle">描述</div>
          <div class="syncbox">
            <div class="boxtitle">{{ Obj.Title }}</div>
            <span class="money" v-if="Obj.Type == 0"
              >报价：{{
                Obj.Money == "面议" ? Obj.Money : Obj.Money + "元"
              }}</span
            >
            <span class="money" v-if="Obj.Type == 1"
              >预算：{{
                Obj.Money == "面议" ? Obj.Money : Obj.Money + "元"
              }}</span
            >
            <span class="times">{{ Obj.PublishTime }}</span>
            <div class="box_btn" @click="getchat(Obj)">立即沟通</div>
          </div>
          <div class="htmlbox" v-html="Obj.Description"  ref="content"
          @click="imageEnlargement"></div>
          <div class="combox" @click="getComInfor(CompanyObj.SysID)">
            <img :src="CompanyObj.Logo" alt="" /><span>{{
              CompanyObj.Name
            }}</span>
          </div>

          <!-- 服务评价 -->
          <!-- <div class="">
            <h5>服务评价</h5>

            <div class="textareabox">
              <el-input
                type="textarea"
                resize="none"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
              <div class="upbtn"><span @click="caretComment">发布</span></div>
            </div>
            <div class="usertextbox">
              <div class="textlist">
                <div class="cctop" v-for="(item, i) in albb" :key="i">
                  <img :src="item.Logo" alt="" />
                  <div class="topright">
                    <div class="userinfor">
                      <span class="username">{{ item.CreateByName }}</span>
                      <i class="usertime">{{ item.CreateOn }}</i>
                    </div>
                    <div class="textinfor">
                      {{ item.Comment }}
                      <span class="backbtn" @click="getbackNews(item)"
                        >回复</span
                      >
                    </div>

                    <div class="textareabox" v-if="backId == item.SysID">
                      <el-input
                        type="textarea"
                        resize="none"
                        :autosize="{ minRows: 3, maxRows: 4 }"
                        placeholder="请输入内容"
                        v-model="textarea"
                      >
                      </el-input>
                      <div class="upbtn"><span  @click="caretComment">发布</span></div>
                    </div>

                    <div
                      class="cctop"
                      v-for="(items, index) in item.children"
                      :key="index"
                    >
                     <img  :src="items.Logo" alt="" />
                    
                      <div class="topright">
                        <div class="userinfor">
                          <span class="username">{{ items.CreateByName }}</span>
                          回复
                          <span class="username">{{ items.ReplyPerson }}</span>
                          <i class="usertime">{{ items.CreateOn }}</i>
                        </div>
                        <div class="textinfor">
                          {{ items.Comment }}
                          <span class="backbtn" @click="getbackNews(items)"
                            >回复</span
                          >
                        </div>

                        <div class="textareabox" v-if="items.SysID == backId">
                          <el-input
                            type="textarea"
                            resize="none"
                            :autosize="{ minRows: 3, maxRows: 4 }"
                            placeholder="请输入内容"
                            v-model="textarea"
                          >
                          </el-input>
                          <div class="upbtn"><span>发布</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="rightbox">
          <div class="card">
            <div class="card_title">该企业同时提供</div>
            <div
              class="othernews"
              v-for="(item, i) in InteractionList"
              :key="i"
              @click="getGinfor(item.SysID)"
            >
              <span class="a_icon">·</span>
              <span class="title">{{ item.Title }}</span>
            </div>
          </div>
          <div class="card">
            <div class="card_title">相关推荐</div>
            <div
              class="comlist"
              v-for="(item, i) in TJList"
              :key="i"
              @click="getGinfor(item.SysID)"
            >
              <div class="imgbox">
                <img :src="item.CompanyLogo" alt="" />
              </div>
              <div class="inforbox">
                <div class="comnames">{{ item.Title }}</div>
                <div class="money">{{ item.CompanyName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->

    <!-- body end -->
    <!-- 聊天窗口 -->
    <div class="chatbox" v-if="ischat" v-drag>
      <div class="msgbox">
        <div class="topbox">
          <!-- <img src="../assets/img/girl.png" alt="" /> -->
          <span>{{ item.Title }}</span>
          <div class="cloese" @click="cloeseChat">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="textboxs" id="new_message">
          <div v-for="(item, i) in mewsList" :key="i">
            <div class="othernew" v-if="!item.IsMine">
              <div class="namebox">
                {{ item.user }} <span>{{ item.time }}</span>
              </div>
              <div class="nexsbox">
                <span>{{ item.Info }}</span>
              </div>
            </div>

            <div class="othernew" v-if="item.IsMine">
              <div class="minebox">
                {{ item.CreateByName }} <span>{{ item.time }}</span>
              </div>
              <div class="newsbox">
                <span>{{ item.Info }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="inputbox">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>

          <div class="sendbox">
            <span @click="seedmsg">发送</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 公共样式

import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      // 评论提交数据
      commentObj: {
        MainSysID: "",
        MainName: "",
        MainCompanySysID: "",
        UserSysID: "",
        CompanySysID: "",
        ParentSysID: "",
        ReplyPerson: "",
        Type: 0,
        Comment: "",
      },
      // 评论数据伪造
      backId: 0,
      albb: [
        {
          text: "这是前端测试数据带有简单的交互逻辑",
          id: 1,
          auser: "小可爱",
          son: [
            {
              text: "这是前端测试数据",
              id: 2,
              auser: "小可爱",
              buser: "大宝贝",
            },
            {
              text: "这是前端测试数据",
              id: 3,
              auser: "老男人",
              buser: "大宝贝",
            },
          ],
        },
      ],
      activeIndex: "4",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      aa: ["不限", "周末双休", "五险一金", "包吃住", "带薪年假"],
      indexaa: 0,
      bb: ["不限", "综合", "电商", "文化创意", "互联网"],
      indexbb: 0,
      height: "400px",
      input2: "",
      Obj: {},
      CompanyObj: {},
      InteractionList: {},
      TJList: {},
      MessageList: {},
      // 聊天相关
      ischat: false,
      getmsgtime: null,
      textarea: "",
      mewsList: [],
      item: {},
       imgArr:[],
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

    // 发布一级评论
    caretComment() {
      let that = this;
      let userId = window.localStorage.getItem("userId");
      let textarea = that.textarea;
      let commentObj = that.commentObj;
      let obj = that.Obj;

      commentObj.MainName = obj.Title;
      commentObj.MainCompanySysID = obj.CompanySysID;
      commentObj.MainSysID = obj.SysID;
      commentObj.Comment = textarea;

      commentObj.UserSysID = userId;
      console.log(obj);
      that.$post("/api/Comment/AddComment", commentObj).then(function (res) {
        console.log(res);
        if (res.Result) {
          that.textarea = "";
          that.getcommentList(obj.SysID);
        }
      });
    },
    // 评论
    getcommentList(id) {
      let that = this;
      that
        .$post("/api/Comment/List", {
          SysID: id,
        })
        .then(function (res) {
          console.log(res);
          if (res.Result) {
            let arr = res.Obj;
            arr.forEach((v) => {
              if (v.CreateOn) {
                v.CreateOn = timeFormat.timeFormat(v.CreateOn, 2);
              }
              if (v.Logo) {
                v.Logo = that.urlOA + v.Logo.substr(1);
              }
              if (v.children.length) {
                v.children.forEach((val) => {
                  val.CreateOn = timeFormat.timeFormat(val.CreateOn, 2);
                  if (val.Logo) {
                    val.Logo = that.urlOA + val.Logo.substr(1);
                  }
                });
              }
            });
            that.albb = arr;
          }
        });
    },
    getbackNews(item) {
      let that = this
      let commentObj = that.commentObj
      console.log(item);
      commentObj.ParentSysID=item.SysID
      commentObj.ReplyPerson=item.CreateByName
      that.backId = item.SysID;
    },
    // 聊天
    getchat(obj) {
      console.log(obj);
      this.item = obj;
      this.getnews();
      this.ischat = true;

      this.getmsgtime = setInterval(() => {
        this.getEachMessage();
      }, 100000);
      this.scrollToBottom();
    },
    cloeseChat() {
      this.ischat = false;
      clearInterval(this.getmsgtime);
    },
    scrollToBottom: function () {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#new_message");
        container.scrollTop = container.scrollHeight;
      });
    },
    // 发送消息
    seedmsg() {
      let that = this;
      let item = that.item;
      let text = that.textarea;
      if (!text.trim()) {
        return;
      }
      let CreateBy = item.CreateBy;
      let SysID = item.SysID;
      let CompanySysID = item.CompanySysID;
      that
        .$post("/api/IMMessage/Add", {
          Receiver: CreateBy,
          CompanySysID,
          Info: text,
          SourceSysID: SysID,
          Type: 0,
        })
        .then(function (res) {
          console.log(res);
          that.mewsList.push(res.Obj);
          that.textarea = "";
          that.scrollToBottom();
        });
    },
    getnews() {
      let that = this;
      let UserID = window.localStorage.getItem("userId");
      let item = that.item;
      let ida = item.CreateBy;
      let UserIDKey = ida + "|" + UserID;
      that
        .$post("/api/IMMessage/MessageList", {
          UserID: UserID,
          UserIDKey,
        })
        .then(function (res) {
          console.log(res);
          that.mewsList = res.Obj;
          that.scrollToBottom();
        });
    },
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },
    getGinfor(item) {
      this.GetInteractionObj(item);
    },
    GetInteractionObj(id) {
      let that = this;
      that
        .$post("/api/Interaction/ObjShow", {
          SysID: id,
        })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            that.Obj = data.Obj.Obj;
            that.Obj.PublishTime = timeFormat.timeFormat(
              that.Obj.PublishTime,
              2
            );

            that.CompanyObj = data.Obj.CompanyObj;
            if (that.CompanyObj.Logo) {
              that.CompanyObj.Logo =
                that.urlOA + that.CompanyObj.Logo.substr(1);
            }

            that.InteractionList = data.Obj.InteractionList;
            data.Obj.TJList.forEach((v) => {
              if (v.CompanyLogo) {
                v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
              }
            });
            that.TJList = data.Obj.TJList;
            that.MessageList = data.Obj.MessageList;
            window.scroll(0, 0);
          } else {
            that.$alert("该互动已下架，请查看其他信息", {
              confirmButtonText: "确定",
              callback: () => {
                that.$router.go(-1);
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    choeseaa(index) {
      console.log(index);
      this.indexaa = index;
    },
    choesebb(index) {
      console.log(index);
      this.indexbb = index;
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    getEachMessage() {
      let that = this;
      let UserID = window.localStorage.getItem("userId");
      let item = that.item;
      let ida = item.CreateBy;
      let UserIDKey = ida + "|" + UserID;
      that
        .$post("/api/IMMessage/EachList", {
          UserID: UserID,
          UserIDKey,
        })
        .then(function (res) {
          console.log(res);
          let meslist = res.Obj;
          if (!meslist.length) {
            return;
          }
          meslist.forEach((element) => {
            that.mewsList.push(element);
          });

          that.scrollToBottom();
        });
    },
  },
  mounted() {
    console.log(this.urlOA);
    let SysID = this.$route.query.SysID;

    console.log(SysID);
    var that = this;
    that.getcommentList(SysID);
    that.GetInteractionObj(SysID);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
  beforeDestroy() {
    clearInterval(this.getmsgtime);
  },
};
</script>


<style scoped  lang="scss">
.textareabox {
  width: 90%;
  margin: 20px 0;
}
.upbtn {
  width: 100%;
  height: 60px;
  span {
    float: right;
    margin-top: 20px;
    color: #fff;
    padding: 2px 20px;
    background-color: royalblue;
    border-radius: 2px;
    cursor: pointer;
  }
}

.cctop {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  img {
    height: 60px;
    width: 60px;
  }
  .topright {
    min-height: 50px;
    margin-top: 5px;
    width: 600px;
    background-color: #fff;
    margin-left: 10px;
    .userinfor {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      .username {
        color: royalblue;
        font-size: 14px;
      }
      .usertime {
        margin-left: 20px;
        font-style: normal;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .textinfor {
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.backbtn {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  color: royalblue;
  cursor: pointer;
  margin-left: 20px;
}
// 聊天窗口
.chatbox {
  position: fixed;
  width: 520px;
  right: 0;
  z-index: 99999999;
  bottom: 0;
  .msgbox {
    //   height: 550px;
    width: 520px;
    // background-color: aqua;
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1px solid #dee0e3;
    margin-bottom: 10px;
    background-color: #fff;

    .topbox {
      position: relative;
      height: 80px;
      background-color: rgb(161, 212, 253);
      overflow: hidden;
      img {
        float: left;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-top: 10px;
        margin-left: 10px;
      }
      span {
        float: left;
        height: 80px;
        line-height: 80px;
        font-weight: 600;
        font-size: 16px;
        margin-left: 10px;
        color: #222;
      }
      .cloese {
        position: absolute;
        cursor: pointer;
        right: 10px;
        font-size: 20px;
        color: #fff;
      }
    }
    .textboxs {
      height: 340px;
      overflow: auto;
      .othernew {
        // background: blueviolet;
        padding: 6px 10px;
        box-sizing: border-box;
        .namebox {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          width: 460px;
          margin-left: 50%;
          transform: translateX(-50%);
          span {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #888;
          }
        }

        .minebox {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          text-align: right;
          width: 460px;
          margin-left: 50%;
          transform: translateX(-50%);
          span {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #888;
          }
        }
      }
    }

    .nexsbox {
      width: 460px;
      margin-left: 50%;
      transform: translateX(-50%);
      overflow: hidden;

      font-size: 14px;

      box-sizing: border-box;
      border-radius: 3px;
      span {
        float: left;
        background-color: #dee0e3;
        line-height: 26px;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }
    .newsbox {
      width: 460px;
      margin-left: 50%;
      transform: translateX(-50%);
      overflow: hidden;

      font-size: 14px;

      box-sizing: border-box;

      span {
        float: right;
        background-color: #cce4fc;
        line-height: 26px;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }
    .inputbox {
      //   height: 160px;
      //   background-color: blueviolet;
      border-top: 1px solid #eee;
      ::v-deep .el-textarea__inner {
        border: none;
        border-radius: 0;
        resize: none;
        height: 90px;
        line-height: 30px;
      }
    }
    .sendbox {
      // height: 40px;
      overflow: hidden;
      // background-color: brown;

      span {
        float: right;
        padding: 3px 20px;
        background-color: #008cff;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        margin-right: 20px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

// 内容区

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  //   background-color: #475669;
  // padding-top: 50px;
  overflow: hidden;
  .leftbox {
    float: left;
    width: 900px;
    .synctitle {
      width: 100%;
      height: 22px;
      line-height: 22px;
      padding-left: 10px;
      font-size: 22px;
      font-weight: 500;
      border-left: 4px solid #3273dc;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .syncbox {
      position: relative;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      overflow: hidden;
      .boxtitle {
        font-size: 24px;
        font-weight: 500;
      }
      .money {
        float: left;
        padding: 2px 10px;
        // background-color: #ed982c;
        color: #ff6633;
        font-size: 14px;
        border-radius: 2px;
        margin-right: 10px;
        // margin-bottom: 10px;
        margin-top: 10px;
      }
      .times {
        float: left;
        padding: 2px 10px;
        background-color: #ed982c;
        color: #fff;
        font-size: 14px;
        border-radius: 2px;
        margin-right: 10px;
        // margin-bottom: 10px;
        margin-top: 10px;
      }

      .box_btn {
        float: right;
        // position: absolute;
        bottom: 6px;
        right: 10px;
        padding: 8px 30px;
        background-color: #3fbcbb;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        margin-top: 2px;
      }
    }
    .htmlbox {
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      ::v-deep p {
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
      ::v-deep li {
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
    .combox {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      margin-bottom: 100px;
      overflow: hidden;
      img {
        float: left;
        cursor: pointer;
        height: 40px;
      }
      span {
        float: left;
        // height: 40px;
        cursor: pointer;
        line-height: 40px;
        font-size: 20px;
        margin-left: 20px;
        font-weight: 700;
      }
    }
  }
  .rightbox {
    float: left;
    width: 300px;
    margin-left: 30px;
    .card {
      padding: 10px 0;
      background-color: #f9f9f9;
      box-sizing: border-box;
      margin-bottom: 50px;
      .card_title {
        border-left: 4px solid #3273dc;
        height: 20px;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 18px;
      }
      .tags_box {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 8px 20px;
          margin: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }
}
.othernews {
  margin-top: 10px;
  height: 30px;
  width: 260px;
  margin-left: 20px;
  //   line-height: 30px;
  border-top: 1px solid #eee;
  font-size: 14px;
  //   padding-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
  .a_icon {
    float: left;
    // display: inline-block;
    height: 30px;
    padding-right: 10px;
    //   padding-top: 10px;
    color: #3273dc;
    font-size: 24px;
    font-weight: 800;
    //   line-height: 30px;
    box-sizing: border-box;
  }
  .title {
    margin-top: 6px;
    margin-left: 15px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.othernews:hover {
  color: #3273dc;
}

.comlist {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  .imgbox {
    float: left;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .inforbox {
    float: left;
    margin-left: 10px;
    width: 200px;

    .comnames {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .money {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #999;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
.htmlbox >>> ul li {
  line-height: 100px;
}
.aaa {
  display: none;
}

@media (max-width: 991px) {
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 500px) {
  .textbox .leftbox {
    float: left;
    width: 100%;
  }
  .textbox .rightbox {
    float: left;
    width: 96%;
    margin-left: 2%;
    .card {
      padding: 10px 0;
      background-color: #f9f9f9;
      box-sizing: border-box;
      margin-bottom: 50px;
      .card_title {
        border-left: 4px solid #3273dc;
        height: 20px;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 18px;
      }
      .tags_box {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 8px 20px;
          margin: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }
  .textbox .leftbox .syncbox .box_btn {
    float: right;
    bottom: 0;
    margin-right: 10px;
    padding: 3px 10px;
    background-color: #3fbcbb;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
  }
  .textbox .leftbox .htmlbox {
    width: 96%;
    margin-top: 20px;
    margin-left: 2%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
  }
  .textbox .leftbox .combox {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 96%;
    margin-top: 20px;
    margin-left: 2%;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
    margin-bottom: 20px;
    overflow: hidden;
    span {
      font-size: 18px;
    }
    img {
      float: left;
      cursor: pointer;
      height: 40px;
      width: 100px;
      object-fit: scale-down;
    }
  }

  .chatbox {
    position: fixed;
    height: 100vh;
    width: 100%;
    right: 0;
    z-index: 99999999;
    bottom: 0;
    // background-color: brown;

    .msgbox {
      height: 100vh;
      width: 100%;
      // background-color: aqua;
      margin-left: 50%;
      transform: translateX(-50%);
      border: 1px solid #dee0e3;
      margin-bottom: 10px;
      background-color: #fff;

      .topbox {
        position: relative;
        height: 10vh;
        background-color: rgb(161, 212, 253);
        overflow: hidden;
        img {
          float: left;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          margin-top: 10px;
          margin-left: 10px;
        }
        span {
          float: left;
          height: 80px;
          line-height: 80px;
          font-weight: 600;
          font-size: 16px;
          margin-left: 10px;
          color: #222;
        }
        .cloese {
          position: absolute;
          cursor: pointer;
          right: 10px;
          font-size: 20px;
          color: #fff;
        }
      }
      .textboxs {
        height: 70vh;
        overflow: auto;
        .othernew {
          // background: blueviolet;
          padding: 6px 10px;
          box-sizing: border-box;
          .namebox {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            width: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
            span {
              display: inline-block;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              color: #888;
            }
          }

          .minebox {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            text-align: right;
            width: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
            span {
              display: inline-block;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              color: #888;
            }
          }
        }
      }

      .nexsbox {
        width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
        overflow: hidden;

        font-size: 14px;

        box-sizing: border-box;
        border-radius: 3px;
        span {
          float: left;
          background-color: #dee0e3;
          line-height: 26px;
          padding: 6px 10px;
          border-radius: 3px;
        }
      }
      .newsbox {
        width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
        overflow: hidden;

        font-size: 14px;

        box-sizing: border-box;

        span {
          float: right;
          background-color: #cce4fc;
          line-height: 26px;
          padding: 6px 10px;
          border-radius: 3px;
        }
      }
      .inputbox {
        //   height: 160px;
        height: 20vh;
        //   background-color: blueviolet;
        border-top: 1px solid #eee;
        ::v-deep .el-textarea__inner {
          border: none;
          border-radius: 0;
          resize: none;
          height: 90px;
          line-height: 30px;
        }
      }
      .sendbox {
        // height: 40px;
        overflow: hidden;
        // background-color: brown;

        span {
          float: right;
          padding: 3px 20px;
          background-color: #008cff;
          border-radius: 3px;
          font-size: 14px;
          color: #fff;
          margin-right: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>